<!--  -->
<template>
  <div class="container">
    <div class="detail-introduce">
        <div class="detail-introduce-h1">{{info.title}}</div>     
    </div>
    <div  class="icon-wapper">
      <div class="item-icon">
        <div class="item-icon-yan">
          <img src="../../static/yan.png">
          <span>{{info.readCount>999?'999+':info.readCount}}</span>
        </div>
        <!-- <div class="item-icon-zan" @click="onzan(info)">
          <img src="../../static/ac_zan.png" v-if="info.iszan">
          <img src="../../static/zan.png" v-else>
          <span>{{info.praiseCount>999?'999+':info.praiseCount}}</span>
        </div> -->
      </div>
    </div>
    <div v-html="info.content.infoDetail" class="htmlInfo rich-text"></div>
  
  </div>
</template>

<script>

export default {
    data () {
        return {
            info:{
                content:{}
            },
            contentId:'',
            topicSymbol:'min-online-info-list',
            openId:'',
        };
    },
    components: {},
    created() {
        this.openId = this.$route.query.openId;
        this.contentId = this.$route.query.contentId;
        this.init();
    },
    methods: {
        init(){
            let postData= {
                topicSymbol: this.topicSymbol,
                contentId:this.contentId,
            }
            console.log(postData);
            this.$api.getContentDetail(postData).then(res=>{
                Object.assign(res.data.data, { iszan: false, isstar:false});
                this.info= res.data.data;    
            })
        }
    },
    
}

</script>
<style scoped>
.detail-introduce{
  position: relative;
  left: 0;
  width: 10rem;
  box-sizing: border-box;
  padding: 0 0.32rem;
}
.detail-introduce-h1{
  font-size: 0.426rem;
  line-height:0.586rem ;
  color: #222222;
  text-indent:0.213rem;
  margin-top: 0.32rem;
  position: relative;
  font-weight: bold;
  padding-bottom: 0.13rem;
}
.detail-introduce-h1::before{
  content: '';
  width: 0.106rem;
  height: 0.426rem;
  position: absolute;
  left: 0;
  top: 0.08rem;
  background:#00A5FF ;
  border-radius:2px;
}
.icon-wapper{
  width:2.67rem;
  height: 0.48rem;
  overflow: hidden;
  margin-left: 0.32rem;
}
.item-icon{
  width: 5rem ;
  height: 0.48rem;
}
.item-icon-yan{
  height: 0.48rem;
  float: left;
  width: 1.546rem;
}
.item-icon-yan img{
  width: 0.48rem;
  height: 0.48rem;
  float: left;
}
.item-icon-yan span{
  font-size: 0.293rem;
  color: #222222;
  line-height: 0.48rem;
  text-indent: 2px;
  float: left;
}
.item-icon-zan{
  height: 0.48rem;
  float: left;
}
.item-icon-zan img{
  width: 0.48rem;
  height: 0.48rem;
  float: left;
}
.item-icon-zan span{
  font-size:  0.293rem;
  color: #222222;
  line-height: 0.48rem;
  text-indent: 2px;
  float: left;
}
.htmlInfo{
    width: 10rem;
    box-sizing: border-box;
    padding: 0 0.32rem;
    margin-top: 0.4rem;
}
</style>